<template>
        <div class="container card">
            <div class="table-wrapper">
               <div class="table-title">
                    <div class="row">
                    <div class="col-sm-6">
                        <h2>My<b>Products</b></h2>
                    </div>
                    
                </div>
               </div>
            </div>
           <table class="table table-striped table-hover display" id="table_id">
                <thead>
                    <tr>
                        <th>Brand Name</th>
                        <th>Generic Name</th>
                        <th>Global Trade Item Number</th>
                        <th>Universal Prodct Code</th>
                        <th>Manufacturing Countries</th>
                        <th>Manufacturing Locations</th>
                        <th>Certification Number</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(product, key) in myProducts" :key="key">
                        <td>{{product.brand_name}}</td>
                        <td>{{product.generic_name}}</td>
                        <td>{{product.gtin}}</td>
                        <td>{{product.upc}}</td>
                        <td>
                            <p v-for="(country, key) in product.manufacturing_countries" :key="key">
                                {{country}}
                            </p>
                        </td>
                        <td>
                            <p v-for="(location, key) in product.manufacturing_locations" :key="key">
                                {{location}}
                            </p>
                        </td>
                        <td>{{product.certification_number[1]}}</td>
                        <td>
                            <button @click="productDetail(product.id)" class="btn btn-success my-2"><i class="fa  fa-eye" aria-hidden="true" data-toggle="tooltip" title="Edit"></i></button>
                            <button @click="getKeyAndId(key, product.id)" class="btn btn-danger my-2" data-toggle="modal" data-target="#exampleModal"><i class="fa  fa-trash-alt" aria-hidden="true" data-toggle="tooltip" title="Delete"></i></button>
                        </td>
                    </tr>  
                </tbody>
            </table>
            <!-- pagination -->

            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete product</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                   Are you sure you want to do this ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button @click="deleteProduct" type="button" class="btn btn-danger" data-dismiss="modal">Confirm</button>
                </div>
                </div>
            </div>
            </div>
        </div>
</template>

<script>
import toasterMixin from '../../mixins/toasterMixin.js'
    export default {
      name:'user_products',
      data() {
          return {
            productID:null,
            key:null,
          }
      }  ,
      computed: {
          myProducts() {
              return this.$store.getters.myProducts
          }
      },
      methods: {
          getKeyAndId(key, id) {
              this.key = key
              this.productID = id
          },
          deleteProduct() {
              const data = {
                  key: this.key,
                  productID: this.productID,
                  entityID: this.$route.params.id
              }

              this.$store.dispatch('deleteProduct',data)
                .then( response => {
                    if(response) {
                        this.toast({
                            message:'Product Deleted.',
                            type:'success'
                        })
                    }
                }).catch( err => {
                    if(err?.response?.data == undefined) {
                        this.toast({
                            message: 'Network error. Check your network connection and try again.',
                            type:'error'
                        })
                    }
                })
          },
          productDetail(productID) {
             this.$router.replace(`/afcfta_link/entity/${this.$route.params.id}/my-product/${productID}`)
          }
      },
      created() {
          this.$store.dispatch('fetchUserProducts', this.$route.params.id)
      }
    }
</script>

<style  scoped>
.table-wrapper{
    background: #fff;
    padding: 20px 25px;
    margin: 30px 0;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title{
    padding-bottom: 15px;
    background: linear-gradient(40deg,#ff2020, #05ffa3);
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 1px 1px 0 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title h2{
    margin: 5px 0 0;
    font-size: 24px;
}

.pagination{
    float: right;
    margin: 0 0 5px;
}

.pagination li a{
    border: none;
    font-size: 14px;
    min-width: 30px;
    min-height: 30px;
    color: #999;
    margin: 0 2px;
    line-height: 30px;
    border-radius: 1px !important;
    text-align: center;
    padding: 0 6px;
}
</style>